import * as React from "react";

import { Page } from "../../types/core";
import { Component, ComponentProps } from "../Component";

/**
 * `<ModulePreview>`
 */
function ModulePreview(props: ModulePreviewProps) {
	const {
		component,
		content,
		library: { components },
	} = props;

	if (!content) {
		return null;
	}

	return (
		<Component libComponents={components} component={component} {...content} />
	);
}

export interface ModulePreviewProps {
	// content: Record<string, unknown>;
	component: string;
	content: Pick<
		Page,
		| "title"
		| "breadcrumb"
		| "fullPath"
		| "fullUrl"
		| "siteSlug"
		| "template"
		| "origin"
		| "site"
		| "modified"
		| "published"
		| "structuredData"
		| "structuredDataContent"
		| "dimensions"
		| "isHome"
	>;
	library: {
		components: Record<string, React.FC<Omit<ComponentProps, "libComponents">>>;
	};
}

export { ModulePreview };
