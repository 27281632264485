import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@ax/types";

import { appActions } from "@ax/containers/App";
import { Login } from "@ax/components";
import { IGlobalSettings } from "@ax/containers/App/reducer";

const LoginModule = (props: IProps) => {
  const { isLoggingIn, globalSettings, login, resetError, getGlobalSettings, setHistoryPush } = props;

  const initState = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const [state, setState] = useState(initState);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const prefix = process.env.REACT_APP_SITE_TITLE ? process.env.REACT_APP_SITE_TITLE : "";
    document.title = `${prefix} ${globalSettings.welcomeText2}`;
  }, [globalSettings]);

  useEffect(() => {
    getGlobalSettings();
  }, [getGlobalSettings]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isLogged = await login(state.email, state.password, state.rememberMe);
    if(isLogged){
      setIsSuccess(true);
    }
  };

  const _handleEmail = (email: string) => {
    resetError();
    setState({ ...state, email });
  };

  const handlePassword = (password: string) => {
    resetError();
    setState({ ...state, password });
  };

  const handleLoginSuccess = () => {
    const welcomePageURI = "/sites";
    setHistoryPush(welcomePageURI);
  }

  const _handleRememberMe = () => setState({ ...state, rememberMe: !state.rememberMe });

  return (
    <Login
      handleSubmit={handleSubmit}
      email={state.email}
      handleEmail={_handleEmail}
      password={state.password}
      handlePassword={handlePassword}
      isLoggingIn={isLoggingIn}
      settings={globalSettings}
      rememberMe={state.rememberMe}
      handleRememberMe={_handleRememberMe}
      isLoginSuccess={isSuccess}
      handleLoginSuccess={handleLoginSuccess}
    />
  );
};

interface IProps {
  token: string;
  isLoggingIn: boolean;
  children: any;
  globalSettings: IGlobalSettings;
  login(email: string, password: string, rememberMe: boolean): Promise<boolean>;
  resetError(): void;
  getGlobalSettings(): void;
  setHistoryPush(path: string): Promise<void>
}

const mapDispatchToProps = {
  login: appActions.login,
  resetError: appActions.resetError,
  getGlobalSettings: appActions.getGlobalSettings,
  setHistoryPush: appActions.setHistoryPush,
};

const mapStateToProps = (state: IRootState) => ({
  isLoggingIn: state.app.isLoggingIn,
  globalSettings: state.app.globalSettings,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModule);
