import * as React from "react";

import { GriddoImagePropsExperimental } from "./types";
import { getGriddoArtDirectionImage, removeCSSUnits } from "./utils";
import { useGriddoImageExp, useSite } from "../../hooks";

/**
 * EXPERIMENTAL - Renders an image from the Griddo CDN using an imageField.
 * Also return a ref with the `<picture>` element.
 *
 * [GriddoImageExp Reference](https://www.notion.so/griddoio/GriddoImageExp-fdb4ea84ac044b1884f242ac854d9c1d?pvs=4)
 */
const GriddoImageExp = React.forwardRef<
	HTMLPictureElement,
	GriddoImagePropsExperimental
>(
	(
		{
			width,
			height,
			widths,
			loading,
			decoding,
			quality,
			crop,
			format,
			formats,
			position,
			transforms,
			sizes,
			sources,
			image: imageField,
			...props
		},
		ref
	) => {
		// No hay imageField.url ni sources
		if (!imageField?.url && !sources) {
			return null;
		}

		// Computed props
		const URL = imageField?.url;
		let WIDTH = width || (imageField?.width && `${imageField?.width}px`) || "";
		let HEIGHT =
			height || (imageField?.height && `${imageField?.height}px`) || "";
		const POSITION = imageField?.position;
		const TITLE = imageField?.title;
		const ALT = imageField?.alt;

		if (width && height) {
			// si customWidth si customHeight =>
			// width=customWidth, height=customHeight
			WIDTH = width;
			HEIGHT = height;
		} else if (!width && height && imageField) {
			// no customWidth si customHeight =>
			// width=computedFieldWidth, height=customHeight
			const imageFieldWidth = imageField.width || 1;
			const imageFieldHeight = imageField.height || 1;
			WIDTH = `${Math.round(
				imageFieldWidth / (imageFieldHeight / removeCSSUnits(height))
			)}px`;
			HEIGHT = height;
		} else if (!width && !height && imageField) {
			// no customWidth no customHeight =>
			// width=fieldWidth, height=fieldHeight
			WIDTH = `${imageField.width}px`;
			HEIGHT = `${imageField.height}px`;
		} else if (width && !height && imageField) {
			// si customWidth no customHeight =>
			// width=customWidth, height=computedCustomHeight
			WIDTH = width;
			const imageFieldWidth = imageField.width || 1;
			const imageFieldHeight = imageField.height || 1;
			HEIGHT = `${Math.round(
				imageFieldHeight / (imageFieldWidth / removeCSSUnits(width))
			)}px`;
		} else if (width && !height && !imageField) {
			// si customWidth no customHeight si field
			// CLS!!!
			WIDTH = width;
			HEIGHT = "";
		}

		// Get instance internal || default settings
		const { griddoDamDefaults } = useSite();

		// IF has sources is an AD image
		const IS_ART_DIRECTION_IMAGE = Array.isArray(sources) && sources.length > 0;

		// Fill Defaults with internals
		const internalGriddoDamDefaults = {
			widths: [
				"640px",
				"750px",
				"828px",
				"1080px",
				"1200px",
				"1920px",
				"2048px",
				"3840px",
			],
			...griddoDamDefaults,
		};

		// ---------------------------------------------------------------------
		// IMAGE CONFIGURATION
		// Configuration from differents places: Image config internal, default
		// settings, component props.
		// ---------------------------------------------------------------------
		const imageConfig = {
			loading: loading || internalGriddoDamDefaults?.loading,
			decoding: decoding || internalGriddoDamDefaults?.decoding,
			quality: quality || internalGriddoDamDefaults?.quality,
			crop: crop || internalGriddoDamDefaults?.crop,
			formats: formats || internalGriddoDamDefaults?.formats,
			widths: widths || internalGriddoDamDefaults?.widths,
			format: format,
			position: position || POSITION,
			transforms: transforms,
		};

		// ---------------------------------------------------------------------
		// Single image raw data.
		// ---------------------------------------------------------------------
		const { src, avif, jpeg, gif, webp, svg } = useGriddoImageExp({
			crop: imageConfig.crop,
			format: imageConfig.format,
			quality: imageConfig.quality,
			position: imageConfig.position,
			transforms: imageConfig.transforms,
			widths: imageConfig.widths,
			width: WIDTH,
			height: HEIGHT,
			url: URL,
		});

		// ---------------------------------------------------------------------
		// ArtDirection image raw data.
		// No need a useGriddoImagexxxx hook, just a regular function.
		// ---------------------------------------------------------------------
		const artDirectionRawData =
			sources &&
			getGriddoArtDirectionImage({
				griddoDamDefaults: internalGriddoDamDefaults,
				crop: imageConfig.crop,
				quality: imageConfig.quality,
				position: imageConfig.position,
				transforms: imageConfig.transforms,
				widths: imageConfig.widths,
				width: WIDTH,
				height: HEIGHT,
				imageField: imageField,
				sources: sources,
				sizes: sizes,
			});

		// ---------------------------------------------------------------------
		// SVG
		// ---------------------------------------------------------------------
		if (format === "svg") {
			return (
				<img
					data-image="griddo"
					data-imageformat="svg"
					loading={loading || imageConfig.loading}
					decoding={decoding || imageConfig.decoding}
					src={svg?.srcSetURL && svg.srcSetURL[0]}
					width={WIDTH}
					height={HEIGHT}
					fetchpriority={props.fetchpriority}
					alt={ALT}
					title={TITLE}
					{...props}
				/>
			);
		}

		// ---------------------------------------------------------------------
		// GIF
		// ---------------------------------------------------------------------
		if (format === "gif") {
			return (
				<img
					data-image="griddo"
					data-imageformat="gif"
					loading={loading || imageConfig.loading}
					decoding={decoding || imageConfig.decoding}
					srcSet={gif?.srcSet?.join(",")}
					sizes={sizes}
					src={gif?.srcSetURL && gif.srcSetURL[0]}
					width={WIDTH}
					height={HEIGHT}
					fetchpriority={props.fetchpriority}
					alt={ALT}
					title={TITLE}
					{...props}
				/>
			);
		}

		// ---------------------------------------------------------------------
		// ART DIRECTION
		// ---------------------------------------------------------------------
		if (IS_ART_DIRECTION_IMAGE) {
			return (
				<picture data-testid="picture" data-artdirection="true">
					{/* AVIF */}
					{imageConfig.formats?.includes("avif") &&
						artDirectionRawData?.avif.map((SOURCE, idx) => (
							<source
								data-imageformat="avif"
								key={idx}
								srcSet={SOURCE.srcSet?.join(",")}
								sizes={SOURCE.sizes}
								media={SOURCE.media}
								type="image/avif"
								width={SOURCE.width}
								height={SOURCE.height}
							/>
						))}

					{/* WEBP */}
					{imageConfig.formats?.includes("webp") &&
						artDirectionRawData?.webp.map((SOURCE, idx) => (
							<source
								data-imageformat="webp"
								key={idx}
								srcSet={SOURCE.srcSet?.join(",")}
								sizes={SOURCE.sizes}
								media={SOURCE.media}
								type="image/webp"
								width={SOURCE.width}
								height={SOURCE.height}
							/>
						))}

					{/* JPEG */}
					{artDirectionRawData?.jpeg.map((SOURCE, idx) => (
						<source
							data-imageformat="jpeg"
							key={idx}
							srcSet={SOURCE.srcSet?.join(",")}
							sizes={SOURCE.sizes}
							media={SOURCE.media}
							type="image/jpeg"
							width={SOURCE.width}
							height={SOURCE.height}
						/>
					))}

					{/* jpeg */}
					<img
						data-image="griddo"
						loading={loading || imageConfig.loading}
						decoding={decoding || imageConfig.decoding}
						sizes={sizes}
						srcSet={artDirectionRawData?.jpeg[0].srcSet?.join(",")}
						src={artDirectionRawData?.jpeg[0].src}
						width={WIDTH || artDirectionRawData?.jpeg[0].width} // Si WIDTH es falsy es que no hay datos en el root de <GriddoImageExp>
						height={HEIGHT || artDirectionRawData?.jpeg[0].height} // Si HEIGHT es falsy es que no hay datos en el root de <GriddoImageExp>
						fetchpriority={props.fetchpriority}
						alt={ALT}
						title={TITLE}
						{...props}
					/>
				</picture>
			);
		}

		// ---------------------------------------------------------------------
		// SINGLE IMAGE
		// ---------------------------------------------------------------------
		return (
			<picture ref={ref} data-testid="picture">
				{/* avif */}
				{imageConfig.formats?.includes("avif") && (
					<source
						srcSet={avif?.srcSet?.join(",")}
						sizes={sizes}
						type="image/avif"
					/>
				)}

				{/* webp */}
				{imageConfig.formats?.includes("webp") && (
					<source
						srcSet={webp?.srcSet?.join(",")}
						sizes={sizes}
						type="image/webp"
					/>
				)}

				{/* jpeg */}
				<img
					data-image="griddo"
					loading={loading || imageConfig.loading}
					decoding={decoding || imageConfig.decoding}
					sizes={sizes}
					srcSet={jpeg?.srcSet?.join(",")}
					src={src}
					width={WIDTH}
					height={HEIGHT}
					fetchpriority={props.fetchpriority}
					alt={ALT}
					title={TITLE}
					{...props}
				/>
			</picture>
		);
	}
);

export { GriddoImageExp };
