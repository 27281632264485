import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult, BeforeCapture } from "react-beautiful-dnd";

import { IModule, INotification, ISchemaField } from "@ax/types";
import { ComponentContainer, SideModal, Toast } from "@ax/components";
import { useBulkSelection, useModal, useToast } from "@ax/hooks";

import { getComponentProps, containerToComponentArray, getTypefromKey, getModulesToPaste } from "../helpers";
import AddItemButton from "./AddItemButton";
import PasteModuleButton from "./PasteModuleButton";
import BulkHeader from "../BulkHeader";

import * as S from "./style";

const MixableComponentArray = (props: IMixableComponentArrayProps): JSX.Element => {
  const {
    whiteList,
    title,
    value,
    goTo,
    selectedContent,
    editorID,
    actions,
    categories,
    maxItems,
    disabled,
    activatedModules,
    objKey,
    field,
    mandatory,
    theme,
    moduleCopy,
    availableDataPacks,
    template,
    setHistoryPush,
  } = props;

  const {
    addModuleAction,
    addComponentAction,
    deleteModuleAction,
    replaceElementsInCollectionAction,
    pasteModuleAction,
    setNotificationAction,
    copyModuleAction,
    duplicateModuleAction,
  } = actions || {};

  // fix for old not array values
  const fixedValue = Array.isArray(value) ? value : containerToComponentArray(value);
  const componentIDs: number[] = fixedValue.map((element: any) => element.editorID);

  const type = getTypefromKey(objKey);
  const { contentType = type } = field;

  const { modulesToPaste, unavailableModules } = getModulesToPaste(
    moduleCopy,
    whiteList,
    availableDataPacks,
    template,
    field
  );

  const { isOpen, toggleModal } = useModal();
  const [isBulkOpen, setIsBulkOpen] = useState(false);
  const [draggingId, setDraggingId] = useState<number | null>(null);
  const { isVisible, toggleToast, setIsVisible, state: toastState } = useToast();

  const { resetBulkSelection, selectedItems, isSelected, checkState, addToBulkSelection, selectAllItems } =
    useBulkSelection(componentIDs);

  useEffect(() => {
    if (selectedItems.all.length) {
      setIsBulkOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.all]);

  const getText = (name: string, index: number) => {
    return fixedValue.length > 1 ? `#${index + 1} ${name}` : name;
  };

  const isComponentModule = contentType === "components";
  const isModuleArr = contentType === "modules";

  const handleAddModule = (moduleType: string) =>
    addModuleAction && addModuleAction(moduleType, objKey, editorID, isComponentModule);

  const handleAddComponent = (componentType: string) => addComponentAction && addComponentAction(componentType, objKey);

  const handleAdd = isModuleArr ? handleAddModule : handleAddComponent;

  const handleModuleReplace = (moduleType: string) => {
    const { modules } = selectedContent;
    if (isModuleArr && deleteModuleAction && modules?.length > 0) {
      const currentModule: IModule = modules[0];
      deleteModuleAction([currentModule.editorID], contentType);
      handleAddModule(moduleType);
    } else {
      replaceElementsInCollectionAction && replaceElementsInCollectionAction(moduleType);
    }
  };

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const showAddItemButton = (!maxItems || fixedValue.length < maxItems) && !disabled && whiteList.length > 0;

  const showPasteModuleButton =
    showAddItemButton &&
    (isModuleArr || objKey === "componentModules") &&
    (modulesToPaste.length > 0 || unavailableModules.length > 0);

  const canReplace = maxItems === 1 && whiteList.length > 1;
  const displayReplaceSideModal = fixedValue.length > 0 && canReplace;
  const optionsType = isModuleArr ? "modules" : "components";

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : null);

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((element: IModule, i: number) => {
      const { editorID } = element;
      const { moduleTitle, isModuleDeactivated, componentTitle, displayName, isModule } = getComponentProps(
        element,
        activatedModules,
        isModuleArr
      );
      const text = getText(componentTitle || displayName, i);
      const isItemSelected = isSelected(editorID);
      const isDraggingSelected = selectedItems.all.includes(draggingId);
      const isGhosting = isItemSelected && !!draggingId && draggingId !== editorID && isDraggingSelected;
      const isMultiDragging = selectedItems.all.length > 1 && draggingId === editorID;

      return (
        <Draggable draggableId={`${editorID}`} index={i} key={editorID}>
          {(provided, snapshot) => (
            <ComponentContainer
              actionReplace={toggleModal}
              canReplace={canReplace}
              isArray={true}
              key={editorID}
              editorID={editorID}
              goTo={goTo}
              text={text}
              moduleTitle={moduleTitle}
              whiteList={whiteList}
              categories={categories}
              actions={actions}
              selectedContent={selectedContent}
              disabled={disabled}
              canDuplicate={showAddItemButton && !isModuleDeactivated}
              parentKey={objKey}
              theme={theme}
              arrayLength={components.length}
              innerRef={provided.innerRef}
              provided={provided}
              isModule={isModule}
              isSelected={isItemSelected}
              onChange={addToBulkSelection}
              hasMenu={selectedItems.all.length > 0}
              toggleToast={toggleToast}
              isMultiDragging={snapshot.isDragging && isMultiDragging}
              draggingCount={selectedItems.all.length}
              className={`${isGhosting ? "ghosting" : ""} ${snapshot.isDragging && isMultiDragging ? "dragging" : ""}`}
            />
          )}
        </Draggable>
      );
    });
  });

  const onDragEnd = (result: DropResult) => {
    const { moveModuleAction } = actions || {};

    if (!moveModuleAction || !result.destination || result.destination.index === result.source.index) {
      setDraggingId(null);
      return;
    }

    if (selectedItems.all.length > 0 && selectedItems.all.includes(parseInt(result.draggableId))) {
      moveModuleAction(selectedItems.all, selectedContent, result.destination.index, objKey);
    } else {
      moveModuleAction([parseInt(result.draggableId)], selectedContent, result.destination.index, objKey);
    }

    setDraggingId(null);
  };

  const onBeforeCapture = (start: BeforeCapture) => setDraggingId(parseInt(start.draggableId));

  const handleCopy = () => {
    const modulesCopied = copyModuleAction && copyModuleAction(selectedItems.all);
    modulesCopied &&
      toggleToast(`${modulesCopied} module${selectedItems.all.length > 1 ? "s" : ""} copied to clipboard`);
  };

  const handleDuplicate = () => {
    if (maxItems && maxItems - fixedValue.length < selectedItems.all.length) {
      const notification: INotification = {
        type: "error",
        text: "Unable to duplicate modules: The destination area has a limit on the number of modules allowed. Please adjust the selection accordingly.",
      };
      setNotificationAction && setNotificationAction(notification);
    } else {
      duplicateModuleAction && duplicateModuleAction(selectedItems.all, objKey);
      resetBulkSelection();
    }
  };

  const handleDelete = () => {
    deleteModuleAction && deleteModuleAction(selectedItems.all, objKey);
    resetBulkSelection();
  };

  const bulkActions = [
    {
      icon: "copy",
      text: "copy",
      action: handleCopy,
    },
    {
      icon: "duplicate",
      text: "duplicate",
      action: handleDuplicate,
    },
    {
      icon: "delete",
      text: "delete",
      action: handleDelete,
    },
  ];

  const toggleBulk = () => {
    setIsBulkOpen(false);
    resetBulkSelection();
  };

  return (
    <S.Wrapper>
      <S.Title>
        {title} <Asterisk />
      </S.Title>
      <S.ItemRow>
        {isBulkOpen ? (
          <BulkHeader
            selectItems={selectItems}
            checkState={checkState}
            totalItems={selectedItems.all.length}
            toggleBulk={toggleBulk}
            actions={bulkActions}
          />
        ) : (
          <S.Subtitle>{fixedValue?.length || 0} items</S.Subtitle>
        )}
        <S.ActionsWrapper data-testid="mixableComponentWrapper">
          {showPasteModuleButton && pasteModuleAction && (
            <PasteModuleButton
              editorID={editorID}
              isModuleCopyUnavailable={unavailableModules.length > 0}
              pasteModule={pasteModuleAction}
              setNotification={setNotificationAction}
              setHistoryPush={setHistoryPush}
              arrayKey={objKey}
              modulesToPaste={modulesToPaste}
              slots={maxItems ? maxItems - fixedValue.length : null}
            />
          )}
          {showAddItemButton && !disabled && (
            <AddItemButton
              isOpen={isOpen}
              toggleModal={toggleModal}
              whiteList={whiteList}
              categories={categories}
              handleClick={handleAdd}
              isModuleArr={isModuleArr}
              theme={theme}
            />
          )}
        </S.ActionsWrapper>
      </S.ItemRow>
      {fixedValue && (
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ComponentList components={fixedValue} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {displayReplaceSideModal && isOpen && (
        <SideModal
          optionsType={optionsType}
          whiteList={whiteList}
          categories={categories}
          toggleModal={toggleModal}
          isOpen={isOpen}
          handleClick={handleModuleReplace}
          theme={theme}
          showSearch
        />
      )}
      {isVisible && <Toast message={toastState} setIsVisible={setIsVisible} />}
    </S.Wrapper>
  );
};

export interface IMixableComponentArrayProps {
  maxItems?: number;
  title: string;
  whiteList: string[];
  value?: IModule[];
  selectedContent: any;
  editorID: number;
  goTo: (editorID: string) => void;
  actions?: {
    addComponentAction: (componentType: any, key?: string) => void;
    addModuleAction: (moduleType: string, key: string, selectedID: number, isComponentModule?: boolean) => void;
    deleteModuleAction: (editorID: number[], key?: string) => void;
    duplicateModuleAction: (editorID: number[], key?: string) => number;
    copyModuleAction: (editorID: number[]) => boolean | number;
    replaceElementsInCollectionAction: (newValue: string, reference?: string) => void;
    moveModuleAction: (moduleID: number[], selectedContent: any, newIndex: number, key: string) => void;
    pasteModuleAction: (editorID: number, key: string, modulesToPaste: IModule[]) => Promise<{ error?: INotification }>;
    setNotificationAction: (notification: INotification) => void;
    replaceModuleAction: (module: any, parent: any, objKey: string) => void;
  };
  categories?: any;
  disabled?: boolean;
  activatedModules: string[];
  objKey: string;
  field: ISchemaField;
  mandatory?: boolean;
  theme: string;
  moduleCopy: { date: Date; elements: IModule[] } | null;
  availableDataPacks: Record<string, any>[];
  template: any;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
}

export default MixableComponentArray;
