import React, { memo, useEffect } from "react";
import { connect } from "react-redux";

import {
  getTemplate,
  isModuleDisabled,
  slugify,
  areEqual,
  filterThemeModules,
  isTemplateExcludedFromTheme,
} from "@ax/helpers";
import { IRootState } from "@ax/types";
import { pageEditorActions } from "@ax/containers/PageEditor";

import TemplateManager from "./TemplateManager";
import Field from "./Field";

const PageConnectedField = (props: any) => {
  const {
    whiteList,
    objKey,
    selectedTab,
    field,
    selectedContent,
    goTo,
    updateEditorContent,
    overwriteHeaderConfig,
    selectedEditorID,
    pages,
    actions,
    setCurrentPageName,
    site,
    lang,
    getPageBreadcrumb,
    activatedTemplates,
    templateConfig,
    activatedModules,
    componentType,
    errors,
    deleteError,
    isGlobal,
    theme,
    moduleCopy,
    availableDataPacks,
    template,
    setHistoryPush,
    languages,
    isReadOnly,
    themeElements,
  } = props;

  const isTemplate = field.type === "template";
  const overrideableFields = ["header", "footer"];
  const isOverride = selectedContent.type && overrideableFields.includes(selectedContent.type);
  const isPageHome = componentType === "page" && selectedContent.isHome;
  const allowModifySlug = !selectedContent.id && !selectedContent.isHome;
  const isNewPage = componentType === "page" && !selectedContent.id;
  const error = errors.find((err: any) => err.editorID === selectedEditorID && err.key === objKey);

  let isTemplateActivated = true;
  if (selectedContent.template && !isGlobal) {
    isTemplateActivated =
      activatedTemplates.find((temp: any) => temp.id === selectedContent.template.templateType) &&
      !isTemplateExcludedFromTheme(themeElements, selectedContent.template.templateType)
        ? true
        : false;
  }

  const parentIsReadOnly =
    (componentType === "page" &&
      objKey === "parent" &&
      isTemplateActivated &&
      templateConfig &&
      templateConfig.modifiableOnPage === false &&
      templateConfig.defaultParent) ||
    isReadOnly;

  const defaultParent =
    isTemplateActivated && templateConfig && templateConfig.defaultParent ? templateConfig.defaultParent : null;

  const hasDefaultIndex = isTemplateActivated && templateConfig && templateConfig.indexDefault !== undefined;

  const isFieldReadOnly = (["parent", "slug"].includes(objKey) && isPageHome) || parentIsReadOnly || field.readonly;

  const filteredActivatedModules = filterThemeModules(themeElements, activatedModules);
  const filteredWhiteList = whiteList ? filterThemeModules(themeElements, whiteList) : whiteList;

  const isDisabled =
    (!isGlobal &&
      (isModuleDisabled(selectedContent.component, componentType, filteredActivatedModules) || !isTemplateActivated)) ||
    isFieldReadOnly ||
    field.disabled;

  const isMetaTitleModified = selectedContent.metaTitle && selectedContent.metaTitle !== selectedContent.title;

  const setDefaultValues = (defaultParent: number | null, hasDefaultIndex: boolean) => {
    defaultParent && updateValue("parent", defaultParent);
    hasDefaultIndex && updateValue("isIndexed", templateConfig.indexDefault);
  };

  useEffect(() => {
    isNewPage && setDefaultValues(defaultParent, hasDefaultIndex);
    // eslint-disable-next-line
  }, [isPageHome]);

  let updateValue = (key: string, value: any, templateID?: number, slugTo?: string) => {
    if (key === "parent") {
      getPageBreadcrumb(value);
    }

    if (key === "isHome") {
      updateEditorContent(selectedEditorID, "parent", null);
      updateEditorContent(selectedEditorID, "slug", value ? "/" : slugify(selectedContent.title));
    }

    if (slugTo) {
      const slugValue = typeof value === "object" && value.content ? value.content : value;
      updateEditorContent(selectedEditorID, slugTo, slugify(slugValue));
    }

    const editorID = templateID ? templateID : selectedEditorID;
    updateEditorContent(editorID, key, value);

    const isPageTitle = ["Page", "GlobalPage"].includes(selectedContent.component) && key === "title";
    if (isPageTitle) {
      setCurrentPageName(value);

      if (allowModifySlug) {
        updateEditorContent(selectedEditorID, "slug", slugify(value));
      }

      if (!isMetaTitleModified) {
        updateEditorContent(selectedEditorID, "metaTitle", value);
      }
    }

    const isComponentImage = ["LinkableImage", "Image"].includes(selectedContent.component) && key === "file";
    if (isComponentImage) {
      updateEditorContent(selectedEditorID, "title", value.title);
      updateEditorContent(selectedEditorID, "alt", value.alt);
    }
  };

  if (isOverride) {
    if (!field.modifiable) return null;

    updateValue = (key: string, value: any) => {
      updateEditorContent(selectedEditorID, key, value);
      const id = selectedContent.id;
      const params = { id, key, value };
      overwriteHeaderConfig(params);
    };
  }

  if (isTemplate) {
    const template = getTemplate(selectedContent[field.key].templateType);

    return (
      <TemplateManager
        template={template}
        updateValue={updateValue}
        goTo={goTo}
        objKey={objKey}
        pages={pages}
        selectedTab={selectedTab}
        selectedContent={selectedContent}
        actions={actions}
        site={site}
        disabled={isDisabled}
        isTemplateActivated={isTemplateActivated}
        deleteError={deleteError}
        errors={errors}
        theme={theme}
        moduleCopy={moduleCopy}
        setHistoryPush={setHistoryPush}
        lang={lang.id}
        activatedModules={filteredActivatedModules}
      />
    );
  }

  return (
    <Field
      whiteList={filteredWhiteList}
      objKey={objKey}
      field={field}
      selectedContent={selectedContent}
      goTo={goTo}
      updateValue={updateValue}
      pages={pages}
      actions={actions}
      site={site}
      lang={lang.id}
      disabled={isDisabled}
      readonly={isFieldReadOnly}
      activatedModules={filteredActivatedModules}
      isTemplateActivated={isTemplateActivated}
      error={error}
      deleteError={deleteError}
      errors={errors}
      theme={theme}
      moduleCopy={moduleCopy}
      availableDataPacks={availableDataPacks}
      template={template}
      setHistoryPush={setHistoryPush}
      languages={languages}
    />
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedEditorID: state.pageEditor.selectedEditorID,
  selectedContent: { ...state.pageEditor.selectedContent },
  pages: state.sites.currentSitePages,
  site: state.sites.currentSiteInfo,
  lang: state.app.lang,
  activatedTemplates: state.dataPacks.templates,
  templateConfig: state.pageEditor.templateConfig,
  activatedModules: state.dataPacks.modules,
  errors: state.pageEditor.errors,
  moduleCopy: state.pageEditor.moduleCopy,
  availableDataPacks: state.dataPacks.available,
  template: state.pageEditor.template,
  languages: state.sites.currentSiteLanguages,
  themeElements: state.sites.themeElements,
});

const mapDispatchToProps = {
  updateEditorContent: pageEditorActions.updateEditorContent,
  overwriteHeaderConfig: pageEditorActions.overwriteHeaderConfig,
  setCurrentPageName: pageEditorActions.setCurrentPageName,
  getPageBreadcrumb: pageEditorActions.getPageBreadcrumb,
  deleteError: pageEditorActions.deleteError,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PageConnectedField, areEqual));
