const IS_BROWSER = typeof window !== "undefined";

export interface GetLinkProps {
	href?: string;
	linkToURL?: string;
}

export interface RecursiveSearchProps {
	obj?: Record<string, string>;
	searchKey?: string;
	resultsBuffer?: Array<string>;
	unique?: boolean;
}

export type PublicApiOperators = "or" | "and" | "OR" | "AND";

export interface ConcatParams {
	baseUrl: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<string, any>;
}

// Return { href, to } from a griddo url object field
function getLinks(url?: GetLinkProps) {
	// Standar external url
	const href = url?.href;
	// linkToURL could be // for internal absolute or / for router links
	const linkToURL = url?.linkToURL;
	// linkToURL: "//cx-web.com/site" or "https(s):"
	const linkToURLHasDoubleSlashOrHttp = linkToURL?.match(/^\/{2}|^https?:/g);
	// linkToURL: "/future-router-link
	const linkToURL_hasSingleSlash = linkToURL?.match(/^\/\w/g);

	const result = {
		href: href ? href : linkToURLHasDoubleSlashOrHttp ? linkToURL : null,
		to: linkToURL_hasSingleSlash ? linkToURL : null,
	};

	return result;
}

// return an anchor if the link is in the same page.
function parseAnchor(pageUrl: string, linkUrl: string) {
	const hasAnchorId = linkUrl?.startsWith(`${pageUrl}#`);
	return hasAnchorId ? `#${linkUrl.split("#")[1]}` : linkUrl;
}

function isLocalStorageAvailable() {
	try {
		const test = "___local_storage_test___";
		localStorage.setItem(test, test);
		localStorage.removeItem(test);
		return true;
	} catch (e) {
		return false;
	}
}

function getToken() {
	if (!isLocalStorageAvailable()) {
		return null;
	}

	const localStorageData = localStorage.getItem("persist:app");

	// No localstorage or browser
	if (!localStorageData || !IS_BROWSER) return null;

	const appInfo = JSON.parse(localStorageData);

	let { token } = appInfo;
	token = JSON.parse(token);
	const authorization = { Authorization: `bearer ${token}` };

	return authorization;
}

function getSiteID() {
	if (!isLocalStorageAvailable()) {
		return null;
	}

	const localStorageData = localStorage.getItem("persist:root");

	// No localstorage or browser
	if (!IS_BROWSER || !localStorageData) return 1;

	const appInfo = JSON.parse(localStorageData);

	let { sites } = appInfo;
	sites = JSON.parse(sites);
	const id = sites.currentSiteInfo?.id;
	return id;
}

function getLang() {
	if (!isLocalStorageAvailable()) {
		return false;
	}

	const localStorageData = localStorage.getItem("langID");

	// No localstorage or browser
	if (!IS_BROWSER || !localStorageData) return false;

	return parseInt(localStorageData);
}

/**
 * recursiveSearch
 * Devuelve una lista con los valores de una prop dentro de un objeto
 * La búsqueda es recursiva en todo el objeto
 * @param {object} config Un objeto con los parámetros de la función
 * @param {object} config.obj El objeto donde buscar
 * @param {string} config.searchKey El nombre de la propiedad a buscar
 * @param {array} config.results El array de resultados, se van añadiendo recursivamente los resultados.
 * @param {boolean} config.unique Si es true elimina los resultados repetidos
 * @example
 * Obtiene una lista con los valores de la prop "a"
 * recursiveSearch({ obj: {a: 1, b: {a: "foo"}}, "a"})
 * -> [1, "foo"]
 *
 * @return {array} Un array con los valores de la propiedad `searchValue`
 */

function recursiveSearch({
	obj,
	searchKey,
	resultsBuffer = [],
	unique = true,
}: RecursiveSearchProps = {}) {
	if (!obj) return [];

	const results = resultsBuffer;
	Object.keys(obj).forEach((key) => {
		const value = obj[key];
		if (key === searchKey && typeof value !== "object") {
			results.push(value);
		} else if (typeof value === "object") {
			value &&
				recursiveSearch({ obj: value, searchKey, resultsBuffer: results });
		}
	});

	return unique ? [...new Set(results)] : results;
}

/**
 * cleanPathSlashes
 * @param {string} url A string
 * @example
 * @returns {string} A string without duplicated slashes
 * cleanPathSlashes('url/with//duplicate///slashes//)
 * -> 'url/with/duplicate/slashes/'
 *
 */
function cleanPathSlashes(url: string) {
	return url.split("/").filter(Boolean).join("/");
}

/**
 * isValidParam
 * @param {string} param A value for the query param
 * @returns {boolean} true if the param is a valid query param
 */
function isValidParam(p: Array<string> | string | number) {
	return (
		(Array.isArray(p) && p.length > 0) ||
		typeof p === "number" ||
		(typeof p === "string" && !!p)
	);
}

/**
 * concatParams
 * @param {object} params
 * @param {string} params.baseUrl base url of the api
 * @param {object} params.params object with the params to be sent
 * @returns {string} a string with the params concatenated
 */
function concatParams({ baseUrl, params }: ConcatParams) {
	const paramsUrl = Object.keys(params)
		.map((key) => (isValidParam(params[key]) ? `${key}/${params[key]}` : ""))
		.join("/");
	return `${baseUrl}${cleanPathSlashes(paramsUrl)}`;
}

export {
	getLinks,
	parseAnchor,
	getToken,
	getSiteID,
	getLang,
	recursiveSearch,
	concatParams,
	isLocalStorageAvailable,
};
