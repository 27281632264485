import React from "react";

import { getInnerFields } from "@ax/forms";
import { FieldContainer, FieldGroup } from "@ax/components";
import { IErrorItem, IPage, ISite, ILanguage } from "@ax/types";

const Field = (props: IFieldProps): JSX.Element => {
  const {
    whiteList,
    objKey,
    field,
    selectedContent,
    goTo,
    pages,
    actions,
    site,
    lang,
    disabled,
    readonly,
    activatedModules,
    isTemplateActivated,
    updateValue,
    categories,
    error,
    deleteError,
    errors,
    theme,
    moduleCopy,
    availableDataPacks,
    template,
    setHistoryPush,
    languages,
  } = props;
  const isGroup = field.type === "FieldGroup";
  const isCollapsed = isGroup && field.collapsed;
  const isConditional = field.type === "ConditionalField";
  const isArrayGroup = field.type === "ArrayFieldGroup";

  let innerFields: JSX.Element[] = [];

  if (isGroup || isConditional || isArrayGroup) {
    const innerActions = { ...actions, updateValue, goTo };
    innerFields = getInnerFields(
      field.fields,
      innerActions,
      selectedContent,
      isTemplateActivated,
      theme,
      disabled,
      site,
      errors,
      deleteError
    );
  }

  return isGroup ? (
    <FieldGroup title={field.title} collapsed={isCollapsed}>
      {innerFields}
    </FieldGroup>
  ) : (
    <FieldContainer
      whiteList={whiteList}
      key={objKey}
      objKey={objKey}
      field={field}
      selectedContent={selectedContent}
      goTo={goTo}
      updateValue={updateValue}
      pages={pages}
      actions={actions}
      site={site}
      lang={lang}
      innerFields={innerFields}
      disabled={disabled}
      readonly={readonly}
      activatedModules={activatedModules}
      categories={categories}
      error={error}
      deleteError={deleteError}
      theme={theme}
      moduleCopy={moduleCopy}
      availableDataPacks={availableDataPacks}
      template={template}
      setHistoryPush={setHistoryPush}
      languages={languages}
    />
  );
};

export interface IFieldProps {
  whiteList: any;
  objKey: string;
  field: any;
  selectedContent: any;
  goTo: any;
  pages: IPage[];
  actions: any;
  site?: ISite;
  lang?: number;
  disabled?: boolean;
  readonly?: boolean;
  activatedModules?: string[];
  isTemplateActivated: boolean;
  updateValue: (key: string, value: any, templateID?: number, slugTo?: string) => void;
  categories?: any;
  error?: IErrorItem;
  deleteError(error: IErrorItem): void;
  errors: IErrorItem[];
  collapsed?: boolean;
  theme: string;
  moduleCopy: { date: string; element: Record<string, unknown> } | null;
  availableDataPacks: Record<string, any>[];
  template: any;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  languages?: ILanguage[];
}

export default Field;
