import React from "react";
import { connect } from "react-redux";
import { pageEditorActions } from "@ax/containers/PageEditor";

import { Browser } from "@ax/components";
import { ILanguage, IRootState, ISchema, ISocialState } from "@ax/types";

const PageBrowser = (props: IProps) => {
  const {
    socials,
    cloudinaryName,
    content: { path, slug, canonicalSite },
    setSelectedContent,
    globalLangs,
    theme,
    isReadOnly,
    isPreview,
    browserRef,
    deleteModule,
    duplicateModule,
  } = props;

  const slugWithSlash = slug ? (slug.startsWith("/") ? slug : `/${slug}`) : "";
  const pathWithoutSlash = path ? (path.endsWith("/") ? path.slice(0, -1) : path) : "";
  const url = `${pathWithoutSlash}${slugWithSlash}`;

  const actions = {
    deleteModuleAction: deleteModule,
    duplicateModuleAction: duplicateModule,
    setSelectedContentAction: setSelectedContent,
  };

  return (
    <Browser
      isPage={true}
      content={props.content}
      socials={socials}
      url={url}
      theme={theme}
      cloudinaryName={cloudinaryName}
      siteLangs={globalLangs}
      disabled={isReadOnly}
      siteID={canonicalSite}
      isPreview={isPreview}
      showIframe={true}
      browserRef={browserRef}
      actions={actions}
    />
  );
};

interface IPageBrowserStateProps {
  // TODO: Define content Type
  content: any;
  socials: ISocialState;
  cloudinaryName: string | null;
  globalLangs: ILanguage[];
  schema: ISchema | Record<string, unknown>;
  selectedParent: any;
}

interface IPageBrowserDispatchProps {
  setSelectedContent(editorID: number): void;
  theme: string;
  isReadOnly: boolean;
  isPreview?: boolean;
  browserRef?: any;
  deleteModule(editorID: number[]): void;
  duplicateModule(editorID: number[]): number;
}

type IProps = IPageBrowserStateProps & IPageBrowserDispatchProps;

const mapStateToProps = (state: IRootState): IPageBrowserStateProps => ({
  content: { ...state.pageEditor.editorContent },
  socials: state.social,
  cloudinaryName: state.app.globalSettings.cloudinaryName,
  globalLangs: state.app.globalLangs,
  schema: state.pageEditor.schema,
  selectedParent: state.pageEditor.selectedParent,
});

const mapDispatchToProps = {
  setSelectedContent: pageEditorActions.setSelectedContent,
  deleteModule: pageEditorActions.deleteModule,
  duplicateModule: pageEditorActions.duplicateModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBrowser);
