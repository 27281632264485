import React from "react";
import { connect } from "react-redux";
import { pageEditorActions } from "@ax/containers/PageEditor";

import { Browser } from "@ax/components";
import { ILanguage, IRootState, ISite, ISocialState } from "@ax/types";

const PageBrowser = (props: IProps) => {
  const {
    socials,
    cloudinaryName,
    content: { header, footer, path, slug },
    setSelectedContent,
    currentSiteInfo,
    siteLangs,
    isTemplateActivated,
    isReadOnly,
    isPreview,
    browserRef,
    deleteModule,
    duplicateModule,
  } = props;

  if (!currentSiteInfo) {
    throw new Error(`ERROR: User reached Page Editor with null site info`);
  }

  const { theme, id: siteID } = currentSiteInfo;

  const slugWithSlash = slug ? (slug.startsWith("/") ? slug : `/${slug}`) : "";
  const pathWithoutSlash = path ? (path.endsWith("/") ? path.slice(0, -1) : path) : "";
  const url = `${pathWithoutSlash}${slugWithSlash}`;
  const disabled = isTemplateActivated === false || isReadOnly;

  const actions = {
    deleteModuleAction: deleteModule,
    duplicateModuleAction: duplicateModule,
    setSelectedContentAction: setSelectedContent,
  };

  return (
    <Browser
      isPage={true}
      content={props.content}
      header={header}
      footer={footer}
      socials={socials}
      url={url}
      theme={theme}
      cloudinaryName={cloudinaryName}
      siteLangs={siteLangs}
      disabled={disabled}
      siteID={siteID}
      isPreview={isPreview}
      showIframe={true}
      browserRef={browserRef}
      actions={actions}
    />
  );
};

interface IPageBrowserStateProps {
  // TODO: Define content Type
  content: any;
  currentSiteInfo: ISite | null;
  socials: ISocialState;
  cloudinaryName: string | null;
  siteLangs: ILanguage[];
  selectedParent: any;
}

interface IPageBrowserDispatchProps {
  setSelectedContent(editorID: number): void;
  isTemplateActivated: boolean;
  isReadOnly: boolean;
  isPreview?: boolean;
  browserRef?: any;
  deleteModule(editorID: number[]): void;
  duplicateModule(editorID: number[]): number;
}

type IProps = IPageBrowserStateProps & IPageBrowserDispatchProps;

const mapStateToProps = (state: IRootState): IPageBrowserStateProps => ({
  content: { ...state.pageEditor.editorContent },
  currentSiteInfo: state.sites.currentSiteInfo,
  socials: state.social,
  cloudinaryName: state.app.globalSettings.cloudinaryName,
  siteLangs: state.sites.currentSiteLanguages,
  selectedParent: state.pageEditor.selectedParent,
});

const mapDispatchToProps = {
  setSelectedContent: pageEditorActions.setSelectedContent,
  deleteModule: pageEditorActions.deleteModule,
  duplicateModule: pageEditorActions.duplicateModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBrowser);
