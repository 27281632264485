import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";

import { IErrorItem, INotification, IRootState, ISavePageParams, ISite, IUserEditing } from "@ax/types";
import {
  MainWrapper,
  Loading,
  ErrorToast,
  Notification,
  Modal,
  ErrorPage,
  CancelScheduleModal,
  ScheduleModal,
} from "@ax/components";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { usersActions } from "@ax/containers/Users";
import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { pageStatus } from "@ax/containers/PageEditor/interfaces";
import { RouteLeavingGuard } from "@ax/guards";
import { useIsDirty, useModal, usePermission } from "@ax/hooks";
import { dateToString, getDefaultTheme } from "@ax/helpers";

import Editor from "./Editor";
import Preview from "./Preview";

import * as S from "./style";

const GlobalEditor = (props: IProps) => {
  const {
    pageID,
    isLoading,
    isSaving,
    pageName,
    lang,
    setLanguage,
    createNewTranslation,
    globalLangs,
    pageLanguages,
    getPage,
    errors,
    setSelectedContent,
    setTab,
    editorContent,
    sitePageID,
    setHistoryPush,
    setCurrentPageID,
    setCurrentSiteInfo,
    savedSiteInfo,
    userEditing,
    isNewTranslation,
    currentSiteErrorPages,
    getUserCurrentPermissions,
    schedulePublication,
  } = props;

  const isAllowedToPublishPages = usePermission("global.globalData.publishUnpublishAllGlobalData");
  const isAllowedToCreatePages = usePermission("global.globalData.createAllGlobalData");
  const isAllowedToDeletePage = usePermission("global.globalData.deleteAllGlobalData");
  const isAllowedToEditContentPage = usePermission("global.globalData.editAllGlobalData");

  const defaultTab = isAllowedToEditContentPage ? "edit" : "view";

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [notification, setNotification] = useState<INotification | null>(null);
  const { isDirty, setIsDirty, resetDirty } = useIsDirty(editorContent, isNewTranslation);
  const [errorPagesChecked, setErrorPagesChecked] = useState(false);
  const [scheduleDate, setScheduleDate] = useState({ date: "", time: "" });
  const { isOpen: isScheduleOpen, toggleModal: toggleScheduleModal } = useModal();
  const { isOpen: isCancelScheduleOpen, toggleModal: toggleCancelScheduleModal } = useModal();
  const browserRef = useRef<HTMLDivElement>(null);

  const isPublished = props.pageStatus === pageStatus.PUBLISHED || props.pageStatus === pageStatus.UPLOAD_PENDING;
  const isDraft = props.pageStatus === pageStatus.MODIFIED;
  const hasDraft: boolean = editorContent && !!editorContent.haveDraftPage;
  const isLivePageChanged = editorContent && editorContent.liveChanged;
  const structuredData = editorContent ? editorContent.structuredData : "";
  const isEditLive = isPublished && hasDraft;
  const isScheduled = props.pageStatus === pageStatus.SCHEDULED;

  const errorNotificationText =
    "There are some errors on the page so you can not publish yet. Please review them in the error panel.";
  const validatedNotificationText = "Everything seems ok, you can publish the page.";

  const theme = getDefaultTheme();

  useEffect(() => {
    const { pageID, getPage, setTab, sendPagePing, setStructuredDataFilter } = props;

    editorContent && editorContent.structuredData && setStructuredDataFilter(editorContent.structuredData);
    const defaultTab = "content";
    const handleGetPage = async () => await getPage(pageID, true);
    setTab(defaultTab);
    handleGetPage();
    if (!pageID) {
      setIsDirty(false);
    }

    const interval = setInterval(() => {
      pageID && sendPagePing(pageID);
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetDirty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    const { pageID, sendPagePing, currentUserID } = props;
    if (userEditing && userEditing.id !== currentUserID) {
      setIsReadOnly(true);
      !isOpen && toggleModal();
    } else {
      setIsReadOnly(false);
      pageID && sendPagePing(pageID);
      isOpen && toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEditing]);

  useEffect(() => {
    if (!errorPagesChecked && !isLoading && editorContent) {
      const pageId = editorContent.id;
      const hasErrors = currentSiteErrorPages.some((errorPageId) => pageId === errorPageId);
      hasErrors && reviewPage();
      setErrorPagesChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent]);

  const setRoute = (path: string) => props.setHistoryPush(path, true);

  const removePage = async () => {
    const { deletePage } = props;
    const path = "/data";

    const deleted = await deletePage();

    if (deleted) {
      setRoute(path);
    }
  };

  const publishPage = async () => {
    const { updatePageStatus, savePage, pageID, validatePage, skipReviewOnPublish } = props;

    const validated = skipReviewOnPublish ? true : await validatePage(true);

    if (validated) {
      const publishPage = {
        status: pageStatus.UPLOAD_PENDING,
      };

      const isSaved = pageID
        ? await updatePageStatus([pageID], pageStatus.UPLOAD_PENDING)
        : await savePage(false, publishPage);

      if (isSaved) {
        resetDirty();
      }
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const publishChanges = async () => {
    const { savePage, validatePage, skipReviewOnPublish } = props;

    const validated = skipReviewOnPublish ? true : await validatePage(true);

    if (validated) {
      const publishPage = {
        status: pageStatus.UPLOAD_PENDING,
      };

      const isSaved = await savePage(false, publishPage);
      if (isSaved) {
        resetDirty();
      }
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const unpublishPage = async () => {
    const { updatePageStatus, pageID } = props;

    const isSaved = await updatePageStatus([pageID], pageStatus.OFFLINE_PENDING);
    if (isSaved) {
      resetDirty();
    }
  };

  const reviewPage = async () => {
    const { validatePage } = props;
    const validated = await validatePage(false);
    if (validated) {
      setNotification({ text: validatedNotificationText, type: "success" });
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const handlePublishDraft = async () => {
    const { savePage, validatePage, skipReviewOnPublish } = props;

    const validated = skipReviewOnPublish ? true : await validatePage(true);

    if (validated) {
      const isSaved = await savePage(false, null, true);
      if (isSaved) resetDirty();
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const handleDiscardDraft = () => {
    const { discardDraft } = props;
    resetDirty();
    discardDraft();
  };

  const handleDiscarChanges = () => {
    const { getPage, pageID } = props;
    resetDirty();
    getPage(pageID, true);
  };

  const handleCreateDraft = async () => {
    const { savePage } = props;

    const isSaved = await savePage(true);
    if (isSaved) resetDirty();
  };

  const handleSchedulePublication = async () => {
    const date = new Date(`${scheduleDate.date} ${scheduleDate.time}`);
    const dateString = dateToString(date, "dd/MM/yyyy HH:mm:ss");
    const saved = await schedulePublication(dateString);
    if (saved) {
      resetDirty();
      toggleScheduleModal();
    }
  };

  const handleCancelSchedulePublication = async () => {
    const saved = await schedulePublication(null);
    if (saved) {
      setScheduleDate({ date: "", time: "" });
      resetDirty();
      toggleCancelScheduleModal();
    }
  };

  const getPublishButton = (status: string) => {
    switch (status) {
      case pageStatus.OFFLINE:
      case pageStatus.OFFLINE_PENDING:
        return {
          label: "Publish",
          action: publishPage,
        };
      case pageStatus.PUBLISHED:
      case pageStatus.UPLOAD_PENDING:
        return !hasDraft && isDirty
          ? {
              label: "Create new draft",
              action: handleCreateDraft,
            }
          : {
              label: "Unpublish",
              action: hasDraft ? toggleUnpublishModal : unpublishPage,
            };
      case pageStatus.MODIFIED:
        return {
          label: "Publish changes",
          action: handlePublishDraft,
        };
      default:
        return null;
    }
  };

  const handleNewTranlation = (isNewTranslation: boolean) => {
    isAllowedToEditContentPage && setSelectedTab("edit");
    createNewTranslation && createNewTranslation(isNewTranslation);
  };

  const languageActions = {
    setLanguage,
    createNewTranslation: handleNewTranlation,
    getContent: getPage,
  };

  const menuOptions = [];

  if (isAllowedToPublishPages && !isScheduled) {
    menuOptions.push({
      label: "Schedule",
      icon: "calendar",
      action: toggleScheduleModal,
    });
  }

  if (isAllowedToPublishPages && isScheduled) {
    menuOptions.push({
      label: "Cancel Schedule",
      icon: "cancelEvent",
      action: toggleCancelScheduleModal,
    });
  }

  if (isAllowedToEditContentPage) {
    menuOptions.push({
      label: "Review",
      icon: "question",
      action: reviewPage,
    });
  }

  if (isAllowedToEditContentPage && (isDraft || (isPublished && isDirty))) {
    menuOptions.push({
      label: "Discard changes",
      icon: "close",
      action: isDraft ? handleDiscardDraft : handleDiscarChanges,
    });
  }

  if (props.pageStatus === pageStatus.PUBLISHED && !hasDraft && isDirty && isAllowedToPublishPages) {
    menuOptions.push({
      label: "Unpublish",
      icon: "offline",
      action: isDraft ? toggleUnpublishModal : unpublishPage,
    });
  }

  if (!isDraft && isAllowedToDeletePage) {
    menuOptions.push({
      label: "Delete",
      icon: "delete",
      action: removePage,
    });
  }

  const downArrowMenu = {
    displayed: !isReadOnly,
    button: isAllowedToPublishPages ? getPublishButton(props.pageStatus) : undefined,
    options: menuOptions,
  };

  const isEditable = editorContent && editorContent.editable;

  const handleSavePage = async () => {
    const { savePage } = props;

    const isSaved = await savePage(false);
    if (isSaved) resetDirty();
  };

  const goToPages = (path: string) => setRoute(path);

  const goToError = (editorID: number, tab: string, template: boolean) => {
    const realEditorID = template ? 0 : editorID;
    setSelectedContent(realEditorID);
    setTab(tab);
  };

  const goToPackage = () => setHistoryPush("/sites/settings/content-types", false);

  const modalText = (
    <>
      Some content <strong>is not saved</strong> on this page.
    </>
  );

  const getSaveLabel = () => {
    switch (props.pageStatus) {
      case pageStatus.PUBLISHED:
      case pageStatus.UPLOAD_PENDING:
        return !isDirty && !isNewTranslation ? "Saved" : isPublished && hasDraft ? "Save & publish" : "Publish changes";
      default:
        return !pageID || isNewTranslation || (isDirty && props.pageStatus !== pageStatus.MODIFIED)
          ? "Save"
          : isDirty && props.pageStatus === pageStatus.MODIFIED
          ? "Save draft"
          : "Saved";
    }
  };

  const rightButtonProps = isAllowedToEditContentPage
    ? {
        label: isSaving ? "Saving" : getSaveLabel(),
        disabled: (!isDirty && pageID !== null && !isNewTranslation) || isSaving || isReadOnly,
        action: isPublished ? publishChanges : handleSavePage,
      }
    : undefined;

  const goToLivePage = () => {
    const currentPageUrl = editorContent.fullUrl;
    if (currentPageUrl) {
      window.open(currentPageUrl, "_blank");
    }
  };

  const toggleDraftPage = async () => {
    const { getPage } = props;
    resetDirty();
    const pageID = isDraft ? editorContent.draftFromPage : editorContent.haveDraftPage;
    await getPage(pageID);
  };

  let pageStatusActions =
    props.pageStatus === pageStatus.PUBLISHED || isDraft
      ? [
          {
            label: "Go to live page",
            icon: "view",
            action: goToLivePage,
          },
        ]
      : [];

  pageStatusActions =
    isDraft || hasDraft
      ? [
          {
            label: isDraft ? "View live" : "Edit draft",
            icon: isDraft ? "active" : "modified",
            action: toggleDraftPage,
          },
          ...pageStatusActions,
        ]
      : pageStatusActions;

  const goBackToSite = () => {
    if (sitePageID) {
      setCurrentSiteInfo(savedSiteInfo);
      setCurrentPageID(sitePageID);
      getUserCurrentPermissions();
      const path = "/sites/pages/editor";
      setHistoryPush(path, true);
    }
  };

  const globalNotificationText = "You’ re working on the Global Page. Make sure you want to make changes to this page.";

  const modifiedNotificationText =
    "You made some changes to the Live version that aren't implemented on this Draft. Check it out before you continue working.";

  const backLinkRoute = "/data";

  const handleGoBack = () => {
    props.setHistoryPush(backLinkRoute, false);
  };

  const mainAction = { title: "Preview Page", onClick: toggleModal };
  const secondaryAction = { title: "Ok, go back", onClick: handleGoBack };

  const mainUnpublishAction = { title: "Ok", onClick: toggleUnpublishModal };

  const mainScheduleModalAction = {
    title: "Schedule",
    onClick: handleSchedulePublication,
  };

  const secondaryScheduleModalAction = { title: "Cancel", onClick: toggleScheduleModal };

  const mainCancelScheduleModalAction = {
    title: "Cancel Schedule",
    onClick: handleCancelSchedulePublication,
  };

  const secondaryCancelScheduleModalAction = { title: "Back", onClick: toggleCancelScheduleModal };

  const tabIcons = isAllowedToEditContentPage
    ? [
        { name: "edit", text: "Edit mode" },
        { name: "view", text: "Preview mode" },
      ]
    : [{ name: "view", text: "Preview mode" }];

  const tabsPreview = {
    icons: tabIcons,
    selectedTab,
    action: (tab: string) => setSelectedTab(tab),
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <RouteLeavingGuard when={isDirty} action={goToPages} text={modalText} />
      <MainWrapper
        title={pageName}
        subtitle={structuredData}
        backLink={backLinkRoute}
        rightButton={rightButtonProps}
        downArrowMenu={downArrowMenu}
        fixedAppBar={true}
        pageStatus={props.pageStatus}
        language={lang}
        languageActions={languageActions}
        availableLanguages={isAllowedToCreatePages ? globalLangs : pageLanguages}
        pageLanguages={pageLanguages}
        currentPageID={pageID}
        fullWidth={true}
        errors={errors}
        errorActions={{ goToError, goToPackage }}
        inversed={true}
        isFromEditor={true}
        pageStatusActions={pageStatusActions}
        tabs={tabsPreview}
        isDirty={isDirty}
        scheduledPublication={editorContent?.publicationScheduled}
      >
        {selectedTab === "edit" ? (
          <>
            {isLivePageChanged && (
              <S.NotificationWrapper>
                <Notification type="warning" text={modifiedNotificationText} />
              </S.NotificationWrapper>
            )}
            {sitePageID && (
              <S.NotificationWrapper>
                <Notification type="info" text={globalNotificationText} btnText="Go Back" onClick={goBackToSite} />
              </S.NotificationWrapper>
            )}
            {notification && (
              <S.NotificationWrapper>
                <Notification
                  type={notification.type}
                  text={notification.text}
                  btnText={notification.btnText}
                  onClick={notification.onClick}
                  resetError={() => setNotification(null)}
                />
              </S.NotificationWrapper>
            )}
            <ErrorToast size="l" fixed />
            <S.Content>
              <Editor
                isGlobal={true}
                isEditable={isEditable}
                isReadOnly={isReadOnly}
                theme={theme}
                browserRef={browserRef}
                setNotification={setNotification}
                isEditLive={isEditLive}
              />
            </S.Content>
          </>
        ) : (
          <Preview theme={theme} />
        )}
        <Modal
          isOpen={isOpen}
          hide={toggleModal}
          size="S"
          title="This page is currently being edited"
          mainAction={mainAction}
          secondaryAction={secondaryAction}
        >
          {isOpen && (
            <S.ModalContent>
              <p>
                <strong>{userEditing && userEditing.name}</strong> is currently working on this page. You can preview
                the page but <strong>you cannot make changes to it</strong> until {userEditing && userEditing.name}{" "}
                leaves the page.
              </p>
            </S.ModalContent>
          )}
        </Modal>
        <Modal
          isOpen={isUnpublishOpen}
          hide={toggleUnpublishModal}
          size="S"
          title="Unpublish Modified Page"
          mainAction={mainUnpublishAction}
        >
          {isUnpublishOpen && (
            <S.ModalContent>
              <p>
                There are some saved changes that are not published on this page. To Unpublish this page,{" "}
                <strong>you must publish these changes or discard them.</strong>
              </p>
            </S.ModalContent>
          )}
        </Modal>
        <ScheduleModal
          isOpen={isScheduleOpen}
          toggleModal={toggleScheduleModal}
          mainModalAction={mainScheduleModalAction}
          secondaryModalAction={secondaryScheduleModalAction}
          scheduleDate={scheduleDate}
          setScheduleDate={setScheduleDate}
        />
        <CancelScheduleModal
          isOpen={isCancelScheduleOpen}
          toggleModal={toggleCancelScheduleModal}
          mainModalAction={mainCancelScheduleModalAction}
          secondaryModalAction={secondaryCancelScheduleModalAction}
        />
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: IRootState): IPageEditorStateProps => ({
  isSaving: state.app.isSaving,
  pageID: state.pageEditor.currentPageID as number,
  pageStatus: state.pageEditor.currentPageStatus as string,
  pageName: state.pageEditor.currentPageName,
  isLoading: state.app.isLoading,
  lang: state.app.lang,
  globalLangs: state.app.globalLangs,
  pageLanguages: state.pageEditor.currentPageLanguages,
  editorContent: state.pageEditor.editorContent,
  errors: state.pageEditor.errors,
  sitePageID: state.pageEditor.sitePageID,
  savedSiteInfo: state.sites.savedSiteInfo,
  userEditing: state.pageEditor.userEditing,
  currentUserID: state.users.currentUser && state.users.currentUser.id,
  isNewTranslation: state.pageEditor.isNewTranslation,
  currentSiteErrorPages: state.sites.currentSiteErrorPages,
  skipReviewOnPublish: state.app.globalSettings.skipReviewOnPublish,
});

interface IPageEditorStateProps {
  pageID: number;
  pageStatus: string;
  pageName: string;
  isSaving: boolean;
  isLoading: boolean;
  lang: { locale: string; id: number | null };
  globalLangs: any[];
  pageLanguages: any[];
  editorContent: any;
  errors: IErrorItem[];
  sitePageID: number | null;
  savedSiteInfo: any;
  userEditing: IUserEditing | null;
  currentUserID: number | null;
  isNewTranslation: boolean;
  currentSiteErrorPages: number[];
  skipReviewOnPublish?: boolean;
}

const mapDispatchToProps = {
  getPage: pageEditorActions.getPage,
  savePage: pageEditorActions.savePage,
  deletePage: pageEditorActions.deletePage,
  validatePage: pageEditorActions.validatePage,
  updatePageStatus: pageEditorActions.updatePageStatus,
  setHistoryPush: appActions.setHistoryPush,
  setLanguage: appActions.setLanguage,
  createNewTranslation: pageEditorActions.createNewTranslation,
  setTab: pageEditorActions.setTab,
  setSelectedContent: pageEditorActions.setSelectedContent,
  setCurrentPageID: pageEditorActions.setCurrentPageID,
  setCurrentSiteInfo: sitesActions.setCurrentSiteInfo,
  sendPagePing: pageEditorActions.sendPagePing,
  setStructuredDataFilter: structuredDataActions.setFilter,
  discardDraft: pageEditorActions.discardDraft,
  getUserCurrentPermissions: usersActions.getUserCurrentPermissions,
  schedulePublication: pageEditorActions.schedulePublication,
};

interface IPageEditorDispatchProps {
  getPage(pageID?: number, global?: boolean): Promise<void>;
  savePage(createDraft: boolean, publishPage?: any, publishDraft?: boolean): Promise<boolean>;
  deletePage(params?: ISavePageParams): Promise<boolean>;
  validatePage(publish?: boolean): Promise<boolean>;
  updatePageStatus(id: number[], status: string): Promise<boolean>;
  setHistoryPush(path: string, isEditor: boolean): void;
  setLanguage?(lang: { locale: string; id: number | null }): void;
  createNewTranslation?(isNewTranslation: boolean): void;
  setTab(tab: string): void;
  setSelectedContent(editorID: number): void;
  setCurrentSiteInfo(currentSiteInfo: ISite | null): void;
  setCurrentPageID(currentPageID: number): void;
  sendPagePing(pageID: number): Promise<boolean>;
  setStructuredDataFilter(filter: string | null): void;
  discardDraft(): Promise<void>;
  getUserCurrentPermissions(): void;
  schedulePublication(date: string | null): Promise<boolean>;
}

type IProps = IPageEditorStateProps & IPageEditorDispatchProps & RouteComponentProps;

const GlobalEditorWithErrorBoundary = withErrorBoundary(GlobalEditor, { fallback: <ErrorPage type="wrongEditor" /> });

export default connect(mapStateToProps, mapDispatchToProps)(GlobalEditorWithErrorBoundary);
