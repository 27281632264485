import * as React from "react";

import { useFetch, UseFetchStateProps } from "./useFetch";
import { usePage } from "./usePage";
import { useSite } from "./useSite";
import { parseRelated } from "./utils";
import { concatParams } from "../functions/utils";
import {
	FiltersDataApiQueryResponseProps,
	PickRename,
	SetQueryProps,
} from "../types/global";

/**
 * `useDataFilters()`
 *
 * Returns an object with filter information to use with ContentTypes.
 *
 * @example
 * const [{ query }, setQuery]  = useDataFilters()
 * setQuery(data) // `data` from a `ReferenceField`
 */
function useDataFilters<ContentType>(): UseDataFiltersReturn<ContentType> {
	// Pull from providers
	const { publicApiUrl, siteId, renderer } = useSite();
	const { languageId } = usePage();

	// State for the data and the query url
	const [url, setUrl] = React.useState<UseDataFilterSetUrlAction>();

	// Check the context: AX or CX
	const isAX = renderer === "editor";

	// Exposed function to modify the internal state
	function setQuery<ContentType = unknown>({
		apiUrl = `${publicApiUrl}`,
		cached,
		data: refField,
		groupingCategories,
		lang = languageId,
		site = siteId,
	}: UseDataFiltersSetQueryProps<ContentType>) {
		if (!refField) {
			return;
		}

		const endPointParam = "/filters/";
		const customCached = isAX ? new Date().valueOf() : cached;
		const structuredData =
			refField.sources?.map(({ structuredData }) => structuredData) ||
			refField.fixed;
		const structuredDataParam = `${structuredData}/`;

		const queryUrl = concatParams({
			baseUrl: `${apiUrl}${endPointParam}${structuredDataParam}`,
			params: {
				site: site,
				lang: lang,
				related: parseRelated(refField.related),
				allLanguages: refField.allLanguages ? "on" : "off",
				filterOperator: refField.sources?.map(
					({ filterOperator }) => filterOperator
				),
				globalOperator: refField.sources?.map(
					({ globalOperator }) => globalOperator
				),
				order: refField.order,
				groupingCategories: groupingCategories ? "on" : "off",
				cached: customCached,
			},
		});

		setUrl({ queryUrl });
	}

	const { isError, isLoading, msg, data, isFirstFetch } = useFetch<
		FiltersDataApiQueryResponseProps<ContentType>
	>(url?.queryUrl);

	return [{ query: data, isLoading, isError, msg, isFirstFetch }, setQuery];
}

// Types
export interface UseDataFilterSetUrlAction {
	queryUrl: string;
}

export type UseDataFiltersSetQueryProps<ContentType> = Pick<
	SetQueryProps<ContentType>,
	"data" | "apiUrl" | "lang" | "site" | "cached" | "groupingCategories"
>;

// The same state as useFetch but renaming data to query for this hook
export type UseDataFiltersState<ContentType> = PickRename<
	UseFetchStateProps<FiltersDataApiQueryResponseProps<ContentType>>,
	"data",
	"query"
>;

export type UseDataFiltersReturn<ContentType> = [
	UseDataFiltersState<ContentType>,
	(setQuery: UseDataFiltersSetQueryProps<ContentType>) => void
];

export { useDataFilters };
