import * as React from "react";
import { AspectRatio } from "react-aspect-ratio";

import "react-aspect-ratio/aspect-ratio.css";
import internalDefaults from "../../constants/cloudinary";
import { useImage, useSite } from "../../hooks";
import {
	CloudinaryImageCrop,
	CloudinaryImageFormat,
	CloudinaryResponsiveImageProps,
} from "../../hooks/useImage";
import {
	CloudinaryDefaults,
	ImageDecoding,
	ImageLoading,
} from "../../types/core";

/**
 * `<CloudinaryImage>`
 *
 * @deprecated This component will be removed from Griddo in the future.
 */
function CloudinaryImage(props: CloudinaryImageProps) {
	const {
		alt,
		width,
		height,
		ratio,
		fixed,
		publicId,
		src,
		objectFit = "cover",
	} = props;

	const { cloudinaryDefaults } = useSite();

	const imageConfig = {
		...internalDefaults,
		...cloudinaryDefaults,
		...props,
	} as CloudinaryDefaults;

	const {
		srcSet,
		sizes,
		src: customSrc,
	} = useImage({
		crop: imageConfig.crop,
		format: imageConfig.format,
		gravity: imageConfig.gravity,
		quality: imageConfig.quality,
		sizes: imageConfig.sizes,
		responsive: imageConfig.responsive,
		ar: ratio,
		publicId: publicId,
	});

	const commonImageProps = {
		alt: alt,
		width: width,
		height: height,
		loading: imageConfig.loading,
		style: { objectFit: objectFit },
		decoding: imageConfig.decoding,
	};

	const srcProps = {
		...commonImageProps,
		src,
	};

	const publicIdProps = {
		...commonImageProps,
		srcSet,
		sizes,
		src: customSrc,
	};

	const imgProps = src ? srcProps : publicIdProps;

	const Image = () => <img {...imgProps} />;

	return fixed ? (
		<Image />
	) : (
		<AspectRatio
			ratio={ratio || 1}
			style={{ maxWidth: "100%", alignSelf: "normal" }}
		>
			<Image />
		</AspectRatio>
	);
}

export interface CloudinaryImageProps {
	alt?: string;
	width?: string;
	height?: string;
	ratio?: number | string;
	fixed?: boolean;
	publicId?: string;
	src?: string;
	objectFit?: React.CSSProperties["objectFit"];
	quality?: number;
	crop?: CloudinaryImageCrop;
	gravity?: string;
	format?: CloudinaryImageFormat;
	loading?: ImageLoading;
	backgroundLazy?: boolean;
	decoding?: ImageDecoding;
	sizes?: string;
	responsive?: Array<CloudinaryResponsiveImageProps>;
}

export { CloudinaryImage };
