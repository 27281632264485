import React from "react";

const SessionContext = React.createContext<ProviderValue>([{}, () => null]);

/**
 * `<SessionProvider>`
 */
const SessionProvider = (props: ProviderProps) => {
	const { children } = props;
	const value = useSessionProvider();

	return (
		<SessionContext.Provider value={value}>{children}</SessionContext.Provider>
	);
};

const useSessionProvider = (): ProviderValue => {
	const [config, setConfig] = React.useState({});
	const changeConfig = (value: { [key: string]: unknown }) =>
		setConfig((state) => ({ ...state, ...value }));
	return [config, changeConfig];
};

interface ProviderProps {
	children: JSX.Element | null;
}

type ProviderValue = [
	Record<string, unknown>,
	(state: Record<string, unknown>) => void
];

export { SessionProvider, SessionContext };
