import * as React from "react";

import { ComponentProps } from "../components/Component";

const getComponent = (
	// components es un objeto con key/value donde value es un componente de React
	components: Record<string, React.FC<Omit<ComponentProps, "libComponents">>>,
	// props pasadas al componente, no sabemos qué se pasará
	props: {
		component: string;
		[key: string]: unknown;
	}
) => {
	const componentName = props.component;
	const isComponent = typeof components[componentName] !== "undefined";

	if (isComponent) {
		return components[componentName];
	}

	console.warn(
		`The component <${componentName}> doesn't exist inside ${JSON.stringify(
			Object.keys(components)
		)}`
	);

	return null;
};

export { getComponent };
