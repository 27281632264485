import { ImageConfig } from "../hooks/useGriddoImage";
import { CloudinaryResponsiveImageProps } from "../hooks/useImage";
import { Core } from "../index";
import { ImageFormats, ResponsiveImageProps } from "../types/core";

function sanitizeUrl(url: string) {
	const [protocol, slug] = url.split(/(^https?:\/\/|^\/\/)/).filter(Boolean);
	const sanitizeSlug = slug?.replace(/\/\/+/g, "/").replace(/\/$/, "");
	return `${protocol}${sanitizeSlug}`;
}

function rearrangeResponsiveArray(arr: Array<ResponsiveImageProps>) {
	const nullBP = arr.filter((entry) => entry.breakpoint === null);
	const mediaBPs = arr.filter((entry) => entry.breakpoint !== null);
	return [...mediaBPs, ...nullBP];
}

function cloudinaryRearrangeResponsiveArray(
	arr?: Array<CloudinaryResponsiveImageProps>
) {
	if (!arr) return [];
	const nullBP = arr.filter((entry) => entry.breakpoint === null);
	const mediaBPs = arr.filter((entry) => entry.breakpoint !== null);
	return [...mediaBPs, ...nullBP];
}

function fillIntArray(n: number, from = 0) {
	return Array(n)
		.fill(0)
		.map((_, idx) => idx + from);
}

function removeUnit(unit?: string) {
	return unit
		?.toString()
		.split(/cm|mm|in|px|pt|pc|em|ex|ch|rem|vw|vh|vmin|vmax|%/)[0];
}

/**
 * _filterFalsePropValues
 * Devuelve un objeto con las propiedades truthy del objeto de entrada
 * @param {object} object Un objeto
 * @returns {object} un nuevo objeto solo con propiedades truthy
 * @example
 * _filterFalsePropValues({a: 1, b: undfined})
 * -> {a: 1}
 */
function _filterFalsePropValues(object: any) {
	return Object.fromEntries(Object.entries(object).filter((tuple) => tuple[1]));
}

/**
 * mergeObject
 * Sobreescribe el objeto2 al objeto1 siempre que las propiedades sean truthy
 * @param {object} object1 Un objeto
 * @param {object} object2 Un objeto
 * @return {object} un nuevo objeto resultado de sobreescribir object2 a object1
 * @example
 * mergeObject({a: 1, b: 0}, {a: 3, b: null})
 * -> {a: 3, b:0}
 */
function mergeObjects(object1: any, object2: any) {
	return {
		..._filterFalsePropValues(object1),
		..._filterFalsePropValues(object2),
	};
}

function getGriddoDamURIWithParams(props: GetGriddoDamURIWithParamsProps) {
	const { damId, imageConfig, ...rest } = props;

	// We want to type imageConfig but to mergeObject doesn't mind it only wants a
	// unkown object
	const params = mergeObjects(
		imageConfig as unknown as UnknownObject,
		rest as UnknownObject
	) as Params;
	const crop = params.crop ? `c/${params.crop}` : "";
	const quality = params.quality ? `q/${params.quality}` : "";
	const format = params.format ? `f/${params.format}` : "";
	const width = params.width ? `w/${removeUnit(params.width as string)}` : "";
	//prettier-ignore
	const height = params.height ? `h/${removeUnit(params.height as string)}` : "";
	const position = params.position ? `p/${params.position}` : "";
	const transforms = params.transforms?.length ? `t/${params.transforms}` : "";

	// Build the URI with the query params
	const queryParamsURL = `${crop}/${quality}/${width}/${height}/${position}/${transforms}/${format}`;
	const fullUrl = sanitizeUrl(
		`${imageConfig.domain}/${queryParamsURL}/${damId}`
	);

	return fullUrl;
}

/**
 * cleanCloudinaryURI
 * Limpia la URI de cloudinary con parámetros sin valor
 * @param {string} URI la parte de la url de cloudinary con los parámetros del
 * query para la imagen.
 * @returns {string} la URI sin valores "nulos"
 * @example
 * Elimina parámetros de la URI sin valores correctos
 * cloeanCloudinaryURI("w_320,q_undefined,f_null,,,g_,h_120")
 * -> "w_320,h_120"
 */
function cleanCloudinaryURI(URI: string) {
	return URI.split(/._null|._undefined|,{1,}|._,|._$|undefined|null/)
		.filter(Boolean)
		.join(",");
}

function getDomainFromDamUrl(damUrl: string) {
	if (damUrl && typeof damUrl === "string") {
		// eslint-disable-next-line no-useless-escape
		return damUrl.split(/(^[http:|https:|\/\/]*[\S][^\/]*)/)[1];
	} else {
		return damUrl;
	}
}

function getDamIdFromDamUrl(damUrl: string) {
	if (damUrl && typeof damUrl === "string") {
		const rest = damUrl.split("/").filter(Boolean).slice(-1)[0];
		return rest;
	} else {
		return damUrl;
	}
}

const rndHash = () =>
	Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.substring(0, 8);

export type UnknownObject = Record<string, unknown>;

interface GetGriddoDamURIWithParamsProps {
	damId: string;
	imageConfig: ImageConfig;
	[key: string]: unknown;
}

interface Params {
	crop?: string;
	quality?: number;
	format?: ImageFormats;
	width?: string;
	height?: string;
	position?: Core.ImagePosition;
	transforms?: Array<Core.ImageTransform>;
}

export {
	rearrangeResponsiveArray,
	cloudinaryRearrangeResponsiveArray,
	removeUnit,
	mergeObjects,
	cleanCloudinaryURI,
	getGriddoDamURIWithParams,
	getDomainFromDamUrl,
	getDamIdFromDamUrl,
	fillIntArray,
	rndHash,
};
