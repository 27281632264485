import * as React from "react";

import {
	getEnvironment,
	getRel,
	getRelativeToRootPath,
	getTarget,
} from "./utils";
import { usePage, useSite } from "../../hooks";
import { Url } from "../../types/api-response-fields";

/**
 * <`GriddoLink>`
 *
 * Renders a router component from internal/external Griddo link.
 * You may want to use `<GriddoLink>` instead of an `<a>` tag for internal Griddo pages.
 * @see [Documentation](https://www.notion.so/griddoio/GriddoLink-bc4f7cddd1484648b024d4bebe4b74c3)
 *
 * @example
 * <GriddoLink
 *   activeClass="activeLink"
 *   url={ linkToURL: 'https://...' }
 * />
 */
function GriddoLink({
	activeClassName = "",
	activeStyle = {},
	style = {},
	children,
	getProps,
	partiallyActive,
	state,
	url,
	title,
	className = "",
	...props
}: GriddoLinkProps) {
	const { renderer, linkComponent: RouterLink } = useSite();
	const { fullPath } = usePage();

	const isEditor = renderer === "editor";

	const href = url?.href;
	const to = url?.linkToURL;
	const newTab = url?.newTab || false;
	const noFollow = url?.noFollow || false;
	const isEmptyLink = !url?.linkToURL && !url?.href;
	const isActiveInEditor = to === `${fullPath?.domainUrl}${fullPath?.compose}`;

	const env = getEnvironment(fullPath);
	const rel = getRel(newTab, noFollow);
	const target = getTarget(newTab);

	// Relative to root path.
	const relativeToRootPath =
		fullPath &&
		getRelativeToRootPath({
			env,
			fullPath,
			to,
		});

	const routerPath = !isEditor && !isEmptyLink ? relativeToRootPath : "";

	// <a> for an external links.
	if (href || newTab) {
		return (
			<a
				data-link="anchor"
				target={target}
				rel={rel}
				href={href || to}
				className={className}
				title={title}
				{...props}
			>
				{children}
			</a>
		);
	}

	// Gatsby <Link> for internal page links.
	return (
		<RouterLink
			rel={rel}
			target={target}
			to={routerPath}
			getProps={getProps}
			partiallyActive={partiallyActive}
			state={state}
			activeStyle={activeStyle}
			activeClassName={activeClassName}
			// Shows link in the editor.
			title={isEditor ? relativeToRootPath : title}
			// Styling in editor, emulating router activeStyle and activeClassName.
			style={isActiveInEditor ? activeStyle : style}
			className={
				isActiveInEditor
					? `${className} ${activeClassName || ""}`.trim()
					: className
			}
			{...props}
		>
			{children}
		</RouterLink>
	);
}

export interface HLocation<S = unknown> {
	pathname: string;
	search: string;
	state: S;
	hash: string;
	key?: string | undefined;
}

export type WindowLocation<S = unknown> = Window["location"] & HLocation<S>;

export interface LinkGetProps {
	/** `true` if the location.pathname is exactly the same as the anchor’s href. */
	isCurrent: boolean;
	/** `true` if the location.pathname is exactly the same as the anchor’s href. */
	isPartiallyCurrent: boolean;
	/** The fully resolved href of the link. */
	href: string;
	/** The app’s location. */
	location: WindowLocation;
}

export interface GriddoLinkProps
	// Remove href, as it's ignored by the router
	extends Omit<React.ComponentPropsWithoutRef<"a">, "href"> {
	/** Griddo Url with the link data */
	url?: Url;
	/** Calls up to you to get props for the underlying anchor element. Useful for styling the anchor as active. */
	getProps?: ((props: LinkGetProps) => Record<string, never>) | undefined;
	/** A class name that will be applied the current item is active. */
	activeClassName?: string;
	/** A style object that will be applied when the current item is active. */
	activeStyle?: React.CSSProperties;
	/** Whether partial URLs are considered active (e.g. /blog#hello-world matches <Link to="/blog"> if partiallyActive is true). */
	partiallyActive?: boolean;
	/** If `true`, the latest entry on the history stack will be replaced with a new one. Use this when you don’t want the previous page to show up when the user clicks the back button. */
	replace?: boolean;
	/** Used to pass state data to the linked page.
	 * The linked page will have a `location` prop containing a nested `state` object structure containing the passed data. */
	state?: Record<string, unknown>;
	/** @deprecated If using React >= 16.4, use ref instead. */
	innerRef?: React.Ref<HTMLAnchorElement> | undefined;
	/** If using React >=16.4, Link will forward its ref to you. */
	ref?: (node: HTMLAnchorElement | null) => React.ReactNode;
	/** onCLick event */
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	children?: React.ReactNode;
}

export { GriddoLink };
