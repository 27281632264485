import React, { useEffect, useRef, useState } from "react";

import { IGlobalSettings } from "@ax/containers/App/reducer";
import { useModal } from "@ax/hooks";
import { Button, FieldsBehavior, ErrorToast, Circle } from "@ax/components";
import RecoveryModal from "./RecoveryModal";

import * as S from "./style";

declare global {
  interface Window {
    handleErrorClick: () => void;
  }
}

const Login = (props: ILoginProps): JSX.Element => {
  const {
    handleSubmit,
    email,
    handleEmail,
    password,
    handlePassword,
    isLoggingIn,
    settings,
    rememberMe,
    handleRememberMe,
    isLoginSuccess,
    handleLoginSuccess,
  } = props;

  const [viewPass, setViewPass] = useState(false);
  const [wrapperWidth, setWrapperWidth] = useState<number>(0);
  const { isOpen, toggleModal } = useModal();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => wrapperRef.current && setWrapperWidth(wrapperRef.current.clientWidth);

    window.addEventListener("resize", handleResize, false);

    if (wrapperRef.current) {
      setWrapperWidth(wrapperRef.current.clientWidth);
    }
  }, [wrapperRef.current]);

  window.handleErrorClick = toggleModal;

  const _handleEmail = (e: string) => handleEmail(e);

  const _handlePwd = (e: string) => handlePassword(e);

  const _togglePassword = () => setViewPass(!viewPass);

  const _handleAnimationEnd = () => handleLoginSuccess();

  const btnText = isLoggingIn ? "Sending" : "Login";

  const inputType = viewPass ? "text" : "password";
  const icon = viewPass ? "hide" : "view";

  const textName = settings.welcomeText2 ? settings.welcomeText2 : "Griddo";
  const nameLogo =
    textName === "Griddo" ? <img src="/img/logos/logoGriddoExtended@3x.svg" alt="Griddo Logo" /> : textName;

  return (
    <S.Wrapper data-testid="login-wrapper">
      <S.LeftWrapper className={isLoginSuccess ? "animate" : ""} onAnimationEnd={_handleAnimationEnd} ref={wrapperRef}>
        <S.LoginWrapper className={isLoginSuccess ? "animate" : ""} width={wrapperWidth}>
          <S.Main>
            <S.Header>
              <S.Title>
                <div>Welcome to</div>
                <div>{nameLogo}</div>
              </S.Title>
              <S.Subtitle>To start using {textName}, introduce your email and password.</S.Subtitle>
            </S.Header>
            <ErrorToast />
            <S.Form onSubmit={handleSubmit}>
              <FieldsBehavior
                fieldType="TextField"
                title="Email"
                autoComplete="email"
                value={email}
                onChange={_handleEmail}
                name="email"
                inversed={true}
                placeholder="Type your email"
              />
              <FieldsBehavior
                fieldType="TextField"
                title="Password"
                inputType={inputType}
                value={password}
                onChange={_handlePwd}
                autoComplete="current-password"
                icon={icon}
                onClickIcon={_togglePassword}
                iconPosition="in"
                name="password"
                inversed={true}
              />
              <S.Actions>
                <FieldsBehavior
                  fieldType="UniqueCheck"
                  title=""
                  value={rememberMe}
                  onChange={handleRememberMe}
                  options={[{ title: "Remember me" }]}
                  name="rememberMe"
                  inversed={true}
                />
                <S.Password>
                  <span
                    onClick={toggleModal}
                    onKeyDown={toggleModal}
                    role="checkbox"
                    aria-checked="false"
                    tabIndex={0}
                    data-testid="forgot-button"
                  >
                    Lost your password?
                  </span>
                </S.Password>
              </S.Actions>
              <Button type="submit" disabled={isLoggingIn ? true : false}>
                {btnText}
              </Button>
              <RecoveryModal isOpen={isOpen} toggleModal={toggleModal} />
            </S.Form>
          </S.Main>
          <S.Secuoyas width={wrapperWidth}>
            Made with care at{" "}
            <a href="https://www.secuoyas.com/" target="_blank">
              <img src="/img/logos/logoSQY.svg" alt="Secuoyas logo" />
            </a>
          </S.Secuoyas>
        </S.LoginWrapper>
      </S.LeftWrapper>
      <S.RightWrapper>
        <Circle animation={1} />
        <Circle animation={2} />
        <S.AnimatedLoginSlider className={isLoginSuccess ? "animate" : ""} />
      </S.RightWrapper>
    </S.Wrapper>
  );
};

export interface ILoginProps {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  handleEmail: (email: string) => void;
  password: string;
  handlePassword: (password: string) => void;
  isLoggingIn: boolean;
  settings: IGlobalSettings;
  rememberMe: boolean;
  handleRememberMe: () => void;
  isLoginSuccess: boolean;
  handleLoginSuccess: () => void;
}

export default Login;
