import { Dispatch } from "redux";

import { removeMenuEditorIds, handleRequest } from "@ax/helpers";
import { generateEditorIDs } from "@ax/forms";
import { menus } from "@ax/api";
import { appActions } from "@ax/containers/App";
import { IMenuItem, IMenu } from "@ax/types";
import {
  SET_ORIGINAL_MENUS,
  SET_EDITOR_MENU,
  SET_ID,
  SET_ITEM,
  SET_MENU_FORM_DATA,
  SET_ORIGINAL_MENU,
  SET_TOTAL_ITEMS,
} from "./constants";
import {
  ISetSavedMenus,
  ISetEditorMenu,
  ISetId,
  ISetItem,
  ISetMenuForm,
  ISetSavedMenu,
  ISetTotalItems,
} from "./interfaces";
import { menuInitialState } from "./reducer";

const { setIsLoading } = appActions;

function setSavedMenus(savedMenus: any): ISetSavedMenus {
  return {
    type: SET_ORIGINAL_MENUS,
    payload: { savedMenus },
  };
}

function setSavedMenu(savedMenu: any): ISetSavedMenu {
  return {
    type: SET_ORIGINAL_MENU,
    payload: { savedMenu },
  };
}

function setEditorMenu(editorMenu: any): ISetEditorMenu {
  return {
    type: SET_EDITOR_MENU,
    payload: { editorMenu },
  };
}

function setId(id: number): ISetId {
  return {
    type: SET_ID,
    payload: { id },
  };
}

function setItem(item: IMenuItem): ISetItem {
  return {
    type: SET_ITEM,
    payload: { item },
  };
}

function setMenuFormData(form: any | null): ISetMenuForm {
  return { type: SET_MENU_FORM_DATA, payload: { form } };
}

function setTotalItems(totalItems: number): ISetTotalItems {
  return { type: SET_TOTAL_ITEMS, payload: { totalItems } };
}

const getStateValues = (getState: any) => {
  const { savedMenus, savedMenu, editorMenu, id, type } = getState().menu;
  const { currentSiteInfo } = getState().sites;
  const { isSaving, isLoading, lang } = getState().app;

  return {
    savedMenus,
    savedMenu,
    editorMenu,
    id,
    type,
    currentSiteInfo,
    isLoading,
    isSaving,
    lang,
  };
};

function setEditorMenuContent(menus: IMenu[], menuType: string, dispatch: Dispatch) {
  const menu: any = menus.find((menu: IMenu) => menu.name === menuType) || menus[0];
  const currentMenu = generateEditorIDs(menu).pageContent;
  const id = menu.id;

  dispatch(setId(id));
  dispatch(setSavedMenu(menu));
  dispatch(setEditorMenu(currentMenu));
  dispatch(setIsLoading(false));
}

function getMenus(): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const { currentSiteInfo, type } = getStateValues(getState);
      const id = currentSiteInfo && currentSiteInfo.id;

      const responseActions = {
        handleSuccess: (menus: IMenu[]) => {
          dispatch(setSavedMenus(menus));
          setEditorMenuContent(menus, type, dispatch);
          updateTotalItems(dispatch, getState);
        },
        handleError: () => console.log("Error en getMenus"),
      };

      const callback = async () => await menus.getMenus(id);

      await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log("Error", e);
    }
  };
}

function getCurrentMenu(menuType: string): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const { savedMenus } = getStateValues(getState);
      setEditorMenuContent(savedMenus, menuType, dispatch);
      updateTotalItems(dispatch, getState);
    } catch (e) {
      console.log("Error", e);
      dispatch(setIsLoading(false));
    }
  };
}

function addMenuItem(item: IMenuItem): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    const { editorMenu } = getStateValues(getState);
    const elements = editorMenu.elements || [];
    const itemWithTemporaryId = { ...item, id: new Date().getTime() };
    const menuElements = [...elements, itemWithTemporaryId];
    const updatedElementsWithInternalIds = generateEditorIDs(menuElements).pageContent;
    const updatedMenu = { ...editorMenu, elements: updatedElementsWithInternalIds };

    dispatch(setEditorMenu(updatedMenu));
    updateTotalItems(dispatch, getState);
  };
}

function updateMenuItem(updatedItem: IMenuItem): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    const { editorMenu } = getStateValues(getState);

    const updateElements = (elements: IMenuItem[]): any => {
      const hasUpdatedChild = elements.some((element) => element.editorID === updatedItem.editorID);
      if (hasUpdatedChild) {
        const elementsWithUpdate = elements.map((item: IMenuItem) => {
          return item.editorID === updatedItem.editorID ? updatedItem : item;
        });
        return elementsWithUpdate;
      }
      for (const element of elements) {
        const updatedChildren = updateElements(element.children);
        if (updatedChildren) {
          element.children = updatedChildren;
          return elements;
        }
      }
    };
    const updatedElements = updateElements([...editorMenu.elements]);

    const updatedMenu = {
      ...editorMenu,
      elements: updatedElements,
    };

    dispatch(setEditorMenu(updatedMenu));
  };
}

function deleteMenuItem(
  deletedItemId: number,
  deleteChildren = true
): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    const { editorMenu } = getStateValues(getState);

    const updateElements = (elements: IMenuItem[]): IMenuItem[] | void => {
      const deletedItem = elements.find((element) => element.id === deletedItemId);
      if (deletedItem) {
        if (deleteChildren) {
          return elements.filter((item) => item.id !== deletedItemId);
        } else {
          const keptChildren = [...deletedItem.children];
          const elementsUpdated = [];
          for (const element of elements) {
            element.id === deletedItemId ? elementsUpdated.push(...keptChildren) : elementsUpdated.push(element);
          }
          return elementsUpdated;
        }
      } else {
        for (const element of elements) {
          const updatedChildren = updateElements(element.children);
          if (updatedChildren) {
            element.children = updatedChildren;
            return elements;
          }
        }
      }
    };

    const updatedElements = updateElements([...editorMenu.elements]);

    const updatedMenu = {
      ...editorMenu,
      elements: updatedElements,
    };

    dispatch(setEditorMenu(updatedMenu));
    updateTotalItems(dispatch, getState);
  };
}

function updateMenu(): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const { savedMenu, editorMenu, type, id, currentSiteInfo } = getStateValues(getState);
      const editorMenuClean = removeMenuEditorIds(editorMenu.elements);
      savedMenu.elements = editorMenuClean;

      const responseActions = {
        handleSuccess: async () => {
          const currentSiteId = currentSiteInfo && currentSiteInfo.id;
          const updatedMenusResponse = await menus.getMenus(currentSiteId);
          const updatedMenus = updatedMenusResponse.data;
          dispatch(setSavedMenus(updatedMenus));
          setEditorMenuContent(updatedMenus, type, dispatch);
        },
        handleError: () => console.log("Error en updateMenu"),
      };

      const callback = async () => menus.updateMenu(savedMenu, id);

      await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log("Error", e);
    }
  };
}

function reorderMenu(elements: IMenuItem[]): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    const { editorMenu } = getStateValues(getState);

    const updatedMenu = {
      ...editorMenu,
      elements,
    };

    dispatch(setEditorMenu(updatedMenu));
  };
}

function setItemValue(page: IMenuItem): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    dispatch(setItem(page));
  };
}

function updateFormValue(valueObj: any): (dispatch: Dispatch, getState: any) => void {
  return (dispatch, getState) => {
    const {
      menu: { form },
    } = getState();
    const updatedForm = { ...form, ...valueObj };

    dispatch(setMenuFormData(updatedForm));
  };
}

function resetItemValues(): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    dispatch(setMenuFormData(menuInitialState.form));
  };
}

const resetMenuValues = (dispatch: Dispatch) => {
  dispatch(setSavedMenus([]));
  dispatch(setEditorMenu([]));
  dispatch(setMenuFormData(menuInitialState.form));
};

const updateTotalItems = (dispatch: Dispatch, getState: any) => {
  const { editorMenu } = getState().menu;

  let counter = 0;
  const calculateItems = (elements: IMenuItem[]): void => {
    for (const element of elements) {
      counter++;
      calculateItems(element.children);
    }
  };
  if (editorMenu.elements) {
    calculateItems([...editorMenu.elements]);
  }

  dispatch(setTotalItems(counter));
};

export {
  setSavedMenus,
  setEditorMenu,
  setId,
  setItem,
  setEditorMenuContent,
  getMenus,
  updateMenu,
  getCurrentMenu,
  addMenuItem,
  updateMenuItem,
  deleteMenuItem,
  setItemValue,
  resetItemValues,
  resetMenuValues,
  reorderMenu,
  updateFormValue,
};
