/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import * as React from "react";

import internalDefaults from "../../constants/cloudinary";
import { fillIntArray, rndHash } from "../../functions/image-utils";
import { useSite, useImage } from "../../hooks";

import "./styles.css";

/**
 * `<CloudinaryBackgroundImage>`
 *
 * @deprecated This component will be removed from Griddo in the future.
 */
function CloudinaryBackgroundImage({
	backgroundRepeat = "no-repeat",
	backgroundSize = "cover",
	backgroundPosition = "50% 50%",
	children,
	overlayColor = null,
	overlayOpacity = 0,
	overlayTransition = "2s",
	ratio,
	publicId,
	src,
	customLazyClassName = "lazy",
	lazy,
	...props
}) {
	const { cloudinaryDefaults } = useSite();

	const imageConfig = {
		...cloudinaryDefaults,
		...internalDefaults,
		...props,
		backgroundLazy:
			cloudinaryDefaults.backgroundLazy ||
			internalDefaults.backgroundLazy ||
			lazy,
	};

	const { srcSetURL } = useImage({
		responsive: [...imageConfig.responsive],
		quality: imageConfig.quality,
		crop: imageConfig.crop,
		gravity: imageConfig.gravity,
		format: imageConfig.format,
		publicId: publicId,
		ar: ratio,
	});

	const SRCSET = src ? [src] : srcSetURL;
	const BREAKPOINTS = src
		? []
		: imageConfig.responsive.map((entry) => entry.breakpoint);
	const CLASSNAME_HASHED = `Griddo-BgImage--${rndHash()}`;
	const LAZY_CLASSNAME = imageConfig.backgroundLazy ? customLazyClassName : "";

	const MEDIA_QUERIES = fillIntArray(SRCSET.length - 1, 1)
		.map((idx) => {
			return `@media (min-width: ${BREAKPOINTS[idx]}) {
            .${CLASSNAME_HASHED} {
              background-image: url(${SRCSET[idx]});
            }
          }
        `;
		})
		.join("");

	return (
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `

      .${CLASSNAME_HASHED} {
        background-repeat: ${backgroundRepeat};
        background-position: ${backgroundPosition};
        background-size: ${backgroundSize};
        background-image: url(${SRCSET[0]});
      }

      ${MEDIA_QUERIES}

      .${CLASSNAME_HASHED}::before {
        transition: all ${overlayTransition} ease;
        background-color: rgba(0, 0, 0, ${overlayOpacity});
        ${
					overlayColor
						? `
            background-color: ${overlayColor};
            opacity: ${overlayOpacity};
          `
						: ""
				}
      }`,
				}}
			/>
			<div className={`Griddo-BgImage ${CLASSNAME_HASHED} ${LAZY_CLASSNAME}`}>
				{children}
			</div>
		</>
	);
}

export { CloudinaryBackgroundImage };
