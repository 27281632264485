import React from "react";

import { IPage, ISite, ILanguage } from "@ax/types";
import { getNullValue, getDefaultSchema, getDefaultTemplate } from "@ax/helpers";

import { FieldsBehavior, HiddenField } from "@ax/components";

const FieldContainer = (props: IFieldContainerProps) => {
  const { selectedContent, updateValue, field, goTo, objKey, lang } = props;

  const { type, key, title, hideable, slugTo } = field;
  const isContainer = field.type === "ComponentContainer";

  const hideField = () => updateValue(key, nullValue);

  const containerValue = selectedContent[objKey] !== undefined ? selectedContent[objKey] : null;

  const fieldProps = {
    fieldType: type,
    value: containerValue,
    hideField,
    onChange: (e: any) => updateValue(key, e, undefined, slugTo),
    goTo,
    editorID: selectedContent.editorID,
    lang,
    ...field,
    ...props,
  };

  const hasMultipleOptions = field.whiteList && field.whiteList.length > 1;
  const nullValue = isContainer && containerValue ? getNullValue(containerValue, hasMultipleOptions) : null;
  const hasNullValue = JSON.stringify(containerValue) === JSON.stringify(nullValue);

  const getField = () => <FieldsBehavior {...fieldProps} key={key} />;

  const getDefaultValue = () => {
    const isTemplate = selectedContent.type === "template";
    const defaultSchema = isTemplate
      ? getDefaultTemplate(selectedContent.templateType)
      : getDefaultSchema(selectedContent.component);
    const component = defaultSchema[key];
    return isContainer ? { ...component, editorID: containerValue.editorID } : component;
  };

  const showField = () => {
    const defaultValue = field.default || getDefaultValue() || "";
    return updateValue(key, defaultValue);
  };

  const getHiddenField = () => <HiddenField key={props.objKey} title={title} showField={showField} />;

  const isHiddenField = hasNullValue && hideable && !hasMultipleOptions && !isContainer;

  return isHiddenField ? getHiddenField() : getField();
};

export default FieldContainer;

export interface IFieldContainerProps {
  key: string;
  field: any;
  selectedContent: any;
  updateValue: any;
  objKey: string;
  goTo?: any;
  whiteList?: any;
  categories?: any;
  pages: IPage[];
  actions?: any;
  hideable?: boolean;
  site?: ISite;
  lang?: number;
  innerFields?: any;
  disabled?: boolean;
  readonly?: boolean;
  activatedModules?: string[];
  error?: any;
  deleteError?(error: any): void;
  theme: string;
  moduleCopy?: { date: string; element: Record<string, unknown> } | null;
  availableDataPacks?: Record<string, unknown>[];
  template?: any;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  languages?: ILanguage[];
}
